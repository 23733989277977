import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Slider } from './styles';

export const SliderComponent = ({ location }) => {
  const data = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "slides/propiercers.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );

  return location['pathname'] === '/' ? (
    <Slider>
      <Img
        durationFadeIn={2000}
        draggable={false}
        fluid={data.placeholderImage.childImageSharp.fluid}
      />
    </Slider>
  ) : null;
};

export default SliderComponent;
