import styled from 'styled-components';

import { COLORS, CONTAINER_WIDTH, DESKTOP_FOOTER_HEIGHT, DEVICE } from '../styles/variables';

export const Footer = styled.footer`
  background-color: ${COLORS.primary};
  display: grid;
  bottom: 0;
  width: 100%;

  @media ${DEVICE.minTablet} {
    position: absolute;
    height: ${DESKTOP_FOOTER_HEIGHT}px;
  }
`;

export const FooterBody = styled.div`
  margin: 30px auto;
  width: 100%;
  display: grid;
  grid-row-gap: 10px;
  width: 100%;
  justify-items: center;

  @media ${DEVICE.minTablet} {
    width: ${CONTAINER_WIDTH}px;
  }
`;

export const FooterRow = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 15px;

  @media ${DEVICE.minMobile} {
    grid-template-columns: auto auto;
    grid-column-gap: 25px;
  }

  @media ${DEVICE.minTablet} {
    grid-auto-flow: column;
    grid-column-gap: 15px;
    width: 100%;
  }

  ${({ center }) =>
    center &&
    `
    justify-content: center;
    grid-template-columns: 180px;
  `};
`;

export const Address = styled.address`
  display: grid;
  font-style: normal;
  color: #b3b3b3;
  text-align: center;

  @media ${DEVICE.minMobile} {
    text-align: left;
  }
`;

export const FooterLinks = styled.div`
  display: grid;
  color: #b3b3b3;
  text-align: center;

  @media ${DEVICE.minMobile} {
    text-align: left;
  }

  ${({ columns }) =>
    columns &&
    `
    grid-template-columns: auto 1fr;
    grid-column-gap: 10px;
  `};

  a {
    color: #b3b3b3;

    &:hover {
      color: #fff;
    }
  }
`;

export const FooterCopyrights = styled.p`
  background-color: ${COLORS.primary};
  font-size: 9px;
  text-align: center;
  padding: 3px 0;
  color: #aaa;
  margin: 0;

  a {
    color: #aaa;

    &:hover {
      text-decoration: underline;
      color: #fff;
    }
  }
`;
