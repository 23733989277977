import React, { useState } from 'react';
import { Link } from 'gatsby';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import LanguageSwitcher from '../LanguageSwitcher';
import Logo from '../../images/logo.inline.svg';

import {
  TopBar,
  MenuToggler,
  MenuWrapper,
  MenuLink,
  HamburgerIcon,
  Header as StyledHeader,
  AddressContainer,
  Links,
} from './styles';

const Header = ({ siteMetadata }) => {
  const [isMenuVisible, toggleShowMenu] = useState(true);
  const buttonStyle = {
    color: '#fff',
    backgroundColor: '#333',
    border: '1px solid #555',
  };
  const { t } = useTranslation('translation');

  return (
    <StyledHeader>
      <CookieConsent
        location="top"
        buttonText={t('cookieAgree')}
        expires={90}
        style={{
          backgroundColor: '#111',
          justifyContent: 'center',
          position: 'relative',
        }}
        contentStyle={{ flex: 'initial' }}
        buttonStyle={buttonStyle}
      >
        {t('cookiesInformation')}
      </CookieConsent>

      <TopBar>
        <MenuToggler onClick={() => toggleShowMenu(!isMenuVisible)}>
          <HamburgerIcon isMenuVisible={!isMenuVisible}></HamburgerIcon>
        </MenuToggler>
        <MenuWrapper isVisible={isMenuVisible}>
          <MenuLink to="/">{t('mainPage')}</MenuLink>
          <MenuLink to="/o-stowarzyszeniu/">{t('aboutUs')}</MenuLink>
          <MenuLink to="/konferencja/">{t('conference')}</MenuLink>
          <MenuLink to="/nasi-piercerzy/">{t('ourPiercers')}</MenuLink>
          <MenuLink to="/faq/">FAQ</MenuLink>
          <MenuLink to="/partnerzy/">{t('partners')}</MenuLink>
          <MenuLink to="/kontakt/">{t('contact')}</MenuLink>
          <MenuLink to="/polityka-prywatnosci/">{t('privacyPolicy')}</MenuLink>
        </MenuWrapper>
      </TopBar>

      <AddressContainer>
        <Link to="/" title={`${siteMetadata.title} - ${t('mainPage')}`}>
          <Logo />
        </Link>

        <Links>
          <LanguageSwitcher />
          <OutboundLink target="_blank" href={`mailto:${siteMetadata.email}`}>
            {siteMetadata.email}
          </OutboundLink>
          <OutboundLink target="_blank" href={`https://${siteMetadata.social.facebook}`}>
            {siteMetadata.social.facebook}
          </OutboundLink>
          <OutboundLink target="_blank" href={`https://${siteMetadata.social.instagram}`}>
            {siteMetadata.social.instagram}
          </OutboundLink>
        </Links>
      </AddressContainer>
    </StyledHeader>
  );
};

export default Header;

