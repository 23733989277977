import styled from 'styled-components';

import { DEVICE } from '../styles/variables';

export const Slider = styled.div`
  display: block;
  margin: 0 auto;

  @media ${DEVICE.minMobile} {
    width: 100%;
  }

  @media ${DEVICE.minTablet} {
    width: 1400px;
  }
`;

export const Slide = styled.img`
  width: 100%;
`;
