import React from 'react';
import { withTranslation } from 'react-i18next';

import { Language, Languages } from './styles';

const LanguageSwitcher = ({ i18n }) => {
  return (
    <Languages>
      <Language onClick={() => i18n.changeLanguage('pl')}>PL</Language>/
      <Language onClick={() => i18n.changeLanguage('en')}>ENG</Language>
    </Languages>
  );
};

export default withTranslation('LanguageSwitcher')(LanguageSwitcher);
