import styled from 'styled-components';
import { CONTAINER_WIDTH, DEVICE } from '../styles/variables';

export const Main = styled.div`
  padding: 0 15px;
  margin: 15px 0;
  max-width: ${CONTAINER_WIDTH}px;

  @media ${DEVICE.minTablet} {
    margin: 50px auto 100px;
    width: ${CONTAINER_WIDTH}px;
  }
`;
