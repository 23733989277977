import styled from 'styled-components';
import { DESKTOP_FOOTER_HEIGHT, DEVICE } from '../styles/variables';

export const PageWrapper = styled.div`
  position: relative;
  padding-bottom: 0;

  @media ${DEVICE.minTablet} {
    padding-bottom: ${DESKTOP_FOOTER_HEIGHT}px;
    min-height: calc(100vh - 200px);
  }
`;
