import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Location } from '@reach/router';

import Header from '../Header';
import Footer from '../Footer';
import Slider from '../Slider';
import Main from '../Main';

import { PageWrapper } from './styles';

import '../styles/reset.css';
import '../styles/layout.scss';
import '../i18n';

export default ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteData {
      site {
        siteMetadata {
          title
          subtitle
          phone
          email
          social {
            facebook
            instagram
          }
          address {
            city
            street
            postal
          }
          account {
            bankName
            number
          }
        }
      }
    }
  `);
  const { siteMetadata } = data.site;

  return (
    <Location>
      {(locationProps) => (
        <PageWrapper>
          <Header siteMetadata={siteMetadata} />
          <Slider {...locationProps} />
          <Main siteData={siteMetadata}>{children}</Main>
          <Footer siteData={siteMetadata} />
        </PageWrapper>
      )}
    </Location>
  );
};
