export const COLORS = {
  primary: '#353535',
};

export const CONTAINER_WIDTH = 1200;
export const DESKTOP_FOOTER_HEIGHT = 200;

const size = {
  mobile: '960px',
  tablet: '1200px',
};

export const DEVICE = {
  minMobile: `(min-width: ${size.mobile})`,
  maxMobile: `(max-width: ${size.mobile - 1})`,
  minTablet: `(min-width: ${size.tablet})`,
};
