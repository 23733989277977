import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { useTranslation } from 'react-i18next';

import Logo from '../../images/logo-reverse.inline.svg';
import { Address, Footer, FooterBody, FooterRow, FooterLinks, FooterCopyrights } from './styles';

const FooterComponent = ({ siteData }) => {
  const { address, email, social, account } = siteData;
  const { t } = useTranslation('translation');

  return (
    <Footer>
      <FooterBody>
        <FooterRow center>
          <Logo height="50" />
        </FooterRow>
        <FooterRow>
          <Address>
            <span>{t('address')}:</span>
            <span>{address.street}</span>
            <span>
              {address.postal} {address.city}
            </span>
          </Address>
          <FooterLinks>
            <OutboundLink target="_blank" href={`mailto:${email}`}>
              {email}
            </OutboundLink>
            <OutboundLink target="_blank" href={`https://${social.facebook}`}>
              {social.facebook}
            </OutboundLink>
            <OutboundLink target="_blank" href={`https://${social.instagram}`}>
              {social.instagram}
            </OutboundLink>
          </FooterLinks>
          <FooterLinks>
            <div>KRS 0000721336</div>
            <div>NIP 8741784157</div>
            <div>REGON 369618300</div>
          </FooterLinks>
          <FooterLinks>
            <span>{t('bankAccount')}</span>
            <span>{account.bankName}</span>
            <span>{account.number}</span>
          </FooterLinks>
        </FooterRow>
      </FooterBody>
      <FooterCopyrights>
        <OutboundLink href="https://www.grzegorzderdak.pl" target="_blank">
          {t('copyrights')}: Grzegorz Derdak 2024
        </OutboundLink>
      </FooterCopyrights>
    </Footer>
  );
};

export default FooterComponent;
