// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dolacz-do-nas-old-js": () => import("./../../../src/pages/dolacz-do-nas.old.js" /* webpackChunkName: "component---src-pages-dolacz-do-nas-old-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-konferencja-js": () => import("./../../../src/pages/konferencja.js" /* webpackChunkName: "component---src-pages-konferencja-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-nasi-piercerzy-js": () => import("./../../../src/pages/nasi-piercerzy.js" /* webpackChunkName: "component---src-pages-nasi-piercerzy-js" */),
  "component---src-pages-o-stowarzyszeniu-js": () => import("./../../../src/pages/o-stowarzyszeniu.js" /* webpackChunkName: "component---src-pages-o-stowarzyszeniu-js" */),
  "component---src-pages-partnerzy-js": () => import("./../../../src/pages/partnerzy.js" /* webpackChunkName: "component---src-pages-partnerzy-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-templates-piercer-piercer-js": () => import("./../../../src/templates/piercer/piercer.js" /* webpackChunkName: "component---src-templates-piercer-piercer-js" */)
}

