import { Link } from 'gatsby';
import styled from 'styled-components';

import { COLORS, CONTAINER_WIDTH, DEVICE } from '../styles/variables';

const { minMobile, minTablet } = DEVICE;
const { primary } = COLORS;

const Container = styled.div`
  grid-auto-flow: row;
  width: 100%;
  margin: 0 auto;
  display: grid;

  @media ${minMobile} {
    grid-auto-flow: column;
  }

  @media ${minTablet} {
    justify-content: space-between;
    width: ${CONTAINER_WIDTH}px;
  }
`;

export const Header = styled.header``;

export const TopBar = styled.div`
  display: grid;
  background-color: ${primary};
  position: relative;
`;

export const MenuWrapper = styled(Container)`
  ${(props) =>
    props.isVisible &&
    `
      position: absolute;
      display: none;
  `}

  @media ${minMobile} {
    display: grid;
    position: relative;
  }
`;

export const AddressContainer = styled(Container)`
  margin: 25px auto;
  padding: 0 15px;
  box-sizing: border-box;
  justify-items: center;
  grid-row-gap: 10px;
  grid-template-columns: max-content max-content;
  justify-items: stretch;

  svg {
    height: 75px;
    width: auto;
  }

  @media ${minMobile} {
    grid-auto-flow: row;
    align-items: center;
    grid-template-columns: 1fr 1fr;

    svg {
      height: 100px;
      width: auto;
    }
  }

  @media ${minTablet} {
    padding: 0;
  }
`;

export const Links = styled.div`
  display: grid;
  grid-row-gap: 5px;
  justify-items: center;

  a {
    color: #888;
    display: block;

    &:hover {
      color: #000;
    }
  }

  @media ${minMobile} {
    justify-items: right;
  }
`;

export const MenuLink = styled(Link)`
  color: #fff;
  padding: 15px 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;

  &:hover {
    text-decoration: underline;
  }
`;

export const HamburgerIcon = styled.span`
  display: block;
  position: absolute;
  top: 15px;
  width: 100%;
  height: 2px;
  background-color: #333;

  &:before {
    content: '';
    display: block;
    background-color: #333;
    height: 2px;
    width: 100%;
    position: absolute;
    top: -7px;
    transition: 0.1s linear;
  }

  &:after {
    content: '';
    display: block;
    background-color: #333;
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: -7px;
    transition: 0.1s linear;
  }

  ${(props) =>
    props.isMenuVisible &&
    `
    background-color: transparent;

    &:before,
    &:after {
      background-color: #fff;
    }

    &:before {
      transform: rotate(45deg);
      top: 0;
    }

    &:after {
      transform: rotate(-45deg);
      bottom: 0;
    }
  `}
`;

export const MenuToggler = styled.div`
  position: absolute;
  display: block;
  width: 25px;
  height: 30px;
  top: 5px;
  right: 15px;

  @media ${minMobile} {
    display: none;
  }
`;
