import styled from 'styled-components';

export const Languages = styled.div`
  color: #888;
  text-align: right;
`;
export const Language = styled.span`
  color: #888;
  margin: 0 5px;

  &:hover {
    color: #000;
    cursor: pointer;
  }
`;
